import { ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

// import { env } from "~/env.mjs"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(input: string | number): string {
  const date = new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

// 每分钟音频对应消耗的积分
const CREDIT_PER_MINUTE = 10
export function getCostCreditNumber(duratin: number): number {
  return Math.ceil(duratin / 60) * CREDIT_PER_MINUTE // 10 credits /分钟
}

// export function absoluteUrl(path: string) {
//   return `${env.NEXT_PUBLIC_APP_URL}${path}`
// }
